import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Cards = ({ cards, className }) => {
  const cardList = []

  const cardClass = className ? className : 'col-lg-3'

  for (let i = 0; i < cards.length; i++) {
    const link = cards[i].link ? (
      <Link to={cards[i].link} className="stretched-link">
        mehr
      </Link>
    ) : (
      ''
    )
    cardList.push(
      <div className={cardClass} key={`cards-${i}`}>
        <div className="card h-100">
          <div className="card-body">
            <h5 className="card-title">{cards[i].title}</h5>
            <p className="card-text">{cards[i].description}</p>
            {link}
          </div>
        </div>
      </div>,
    )
  }

  return (
    <div className="container marketing">
      <div className="row">{cardList}</div>
    </div>
  )
}

Cards.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      link: PropTypes.string,
    }),
  ).isRequired,
}

export default Cards

import * as React from 'react'
import PropTypes from 'prop-types'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { v4 as uuidv4 } from 'uuid'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

class Carousel extends React.Component {
  carouselID = uuidv4()
  carouselReference = React.createRef()
  bootstrapCarousel

  componentDidMount() {
    this.bootstrapCarousel = new bootstrap.Carousel(
      this.carouselReference.current,
      {
        ride: 'carousel',
      },
    )

    this.bootstrapCarousel.cycle()
  }

  render() {
    const { carousel } = this.props

    const indicatorButtons = []
    const carouselItems = []

    for (let i = 0; i < carousel.length; i++) {
      if (i === 0) {
        indicatorButtons.push(
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
            key={`carouselBtn-${i}`}
          ></button>,
        )
      } else {
        indicatorButtons.push(
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to={i}
            aria-label={`Slide ${i + 1}`}
            key={`carouselBtn-${i}`}
          ></button>,
        )
      }

      const image =
        getImage(carousel[i].background_image) || carousel[i].background_image

      const classes = `carousel-item ${i === 0 ? 'active' : ''}`

      carouselItems.push(
        <div className={classes} key={`carousel-${i}`}>
          {image.url ? (
            <img src={image} className="carousel-item-img" alt={''} />
          ) : (
            <GatsbyImage image={image} className="carousel-item-img" alt={''} />
          )}

          <div className="container">
            <div className="carousel-caption text-start">
              <h1>{carousel[i].title}</h1>
              <p>{carousel[i].description}</p>
            </div>
          </div>
        </div>,
      )
    }

    return (
      <div
        id={`carousel-${this.carouselID}`}
        ref={this.carouselReference}
        className="carousel slide"
      >
        <div className="carousel-indicators">{indicatorButtons}</div>
        <div className="carousel-inner">{carouselItems}</div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={`#carousel-${this.carouselID}`}
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={`#carousel-${this.carouselID}`}
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    )
  }
}

Carousel.propTypes = {
  carousel: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      background_image: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
      ]),
    }),
  ).isRequired,
}

export default Carousel

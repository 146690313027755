import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Carousel from '../components/Carousel'
import Cards from '../components/Cards'

// eslint-disable-next-line
export const IndexPageTemplate = ({ carousel, cards }) => {
  return (
    <>
      <Carousel carousel={carousel} />
      <Cards cards={cards} />
    </>
  )
}

IndexPageTemplate.propTypes = {
  carousel: Carousel.propTypes.carousel,
  cards: Cards.propTypes.cards,
}

const IndexPage = ({ data, pageContext }) => {
  const { carousel, cards } = data.markdownRemark.frontmatter
  return (
    <Layout pageContext={pageContext}>
      <IndexPageTemplate carousel={carousel} cards={cards} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape(IndexPageTemplate.propTypes).isRequired,
    }),
  }),
  pageContext: PropTypes.object.isRequired,
}

export default IndexPage

export const indexPageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        carousel {
          title
          description
          background_image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        cards {
          title
          description
          link
        }
      }
    }
  }
`
